import React from 'react'
import SEO from '~/components/seo'

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <h1>PAGE NOT FOUND</h1>
    <p>ページがありません。URLをお確かめください。</p>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </>
)

export default NotFoundPage
